import React from "react";
import "./navbar.css";
import { Link } from "gatsby";
import { Container, Row, Nav, Navbar, NavDropdown } from "react-bootstrap";

import logo from "../../assets/global/st-marouns-crest-2020-with-text.png";

const navbar = () => {
  return (
    <div className={"header-wrapper"}>
      <Container>
        <Row>
          <Navbar expand="lg">
            <Link to="/" className="navbar-brand">
              <img src={logo} alt="" />
            </Link>

            <Navbar.Toggle aria-controls="basic-navbar-nav" />

            <Navbar.Collapse id="basic-navbar-nav">
              <div className={"top-nav-wrapper"}>
                <Nav className="mr-auto">
                  <Link to="/">
                    <i className="fas fa-search"></i>
                  </Link>
                  {/*<Link to="/">Login</Link>*/}
                  <Link to="/contact">Contact Us</Link>
                  <Nav.Link href="tel:+61(02)95592434">
                    +61 (02) 9559 2434
                  </Nav.Link>
                </Nav>
              </div>
              <Nav className={"main-menu"}>
                <NavDropdown title="About Us" id="basic-nav-dropdown">
                  <Link to="/about/history">Our History</Link>
                  <Link to="/about/fromtheprincipal">From The Principal</Link>
                  <Link to="/about/mission">Mission, Vision & Values</Link>
                  <Link to="/about/annualreport">Annual Report</Link>
                  <Link to="/about/policies">Policies</Link>
                  <Link to="/about/employment">Employment</Link>
                </NavDropdown>

                <NavDropdown
                  title="Educational Excellence"
                  id="basic-nav-dropdown"
                >
                  <Link to="/education/primaryyear">Primary Years</Link>
                  <Link to="/education/secondaryyear">Secondary Years</Link>
                  <Link to="/education/pastoralcare">Pastoral Care</Link>
                  <Link to="/education/hscresults2022">HSC Results 2022</Link>
                </NavDropdown>
                
                <NavDropdown title="News and Events" id="news-and-events">
                <Link to="/newsletter">Newsletter</Link>
                <Link to="/newsevents">News & Events</Link>
                </NavDropdown>
                <NavDropdown title="Enrolments" id="basic-nav-dropdown">
                  <Link to="/enrolment">Enrolment Process</Link>
                  <Link to="/scheduleoffees">Schedule of Fees</Link>
                  <Link to="/scholarships">Scholarships</Link>
                  <Link to="/international">International Enrolments</Link>
                </NavDropdown>

                <NavDropdown title="Community" id="community">
                  <Link to="/ourcommunity">Our Community</Link>
                  <Link to="/kissandgo">Kiss and Go</Link>
                </NavDropdown>

              </Nav>
            </Navbar.Collapse>
          </Navbar>
        </Row>
      </Container>
    </div>
  );
};

export default navbar;
