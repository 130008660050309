import React from "react";
import PropTypes from "prop-types";

import "./styles/bootstrap.min.css";
import "./styles/master.css";
import "../styles/util.css";
// import "./layout.css";

import Navbar from "../components/master/navbar";
import Footer from "./master/footer";

const Layout = ({children}) => (
        <>
            <head>
                <title>St Marouns College</title>
                <meta charSet="UTF-8"/>
                <meta name="viewport" content="width=device-width, initial-scale=1"/>
                {/*<link rel="icon" type="image/png" href="../../assets/icons/favicon.ico"/>*/}
                <link
                    href="https://cdn.jsdelivr.net/npm/bootstrap@5.0.0-beta3/dist/css/bootstrap.min.css"
                    rel="stylesheet"
                    integrity="sha384-eOJMYsd53ii+scO/bJGFsiCZc+5NDVN2yr8+0RDqr0Ql0h+rP48ckxlpbzKgwra6"
                    crossOrigin="anonymous"
                />
                <link
                    href="https://use.fontawesome.com/releases/v5.12.1/css/all.css"
                    rel="stylesheet"
                />
            </head>
            <div>
                <Navbar/>
                <main>{children}</main>
                <Footer/>
            </div>
        </>
    )
;

Layout.propTypes = {
    children: PropTypes.node.isRequired,
};

export default Layout;
