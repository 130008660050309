import { Link } from "gatsby";
import React from "react";

import termDates2023 from "../../assets/global/2023_Term_Dates.pdf";
import termDates from "../../assets/global/Term_Dates.pdf";



import "./footer.css";

const Footer = () => (
  <div className={"container-fluid mainFooter"}>
    <div className="container">
      <div className="row">
        <div className="col-md-4">
          <div className="footer-block">
            <h4>Contact Details</h4>
            <ul>
              <li>
                <span>
                 St Maroun's College
                </span>
              </li>
              <li>
                <span>
                 CRICOS: 03814D
                </span>
              </li>
              <li>
                <span>
                  <i className="fas fa-phone-alt"></i>
                </span>{" "}
                <span>
                  <a href="tel:+61295592434">+61 295 592 434</a>
                </span>
              </li>
              <li>
                <span>
                  <i className="fas fa-envelope"></i>
                </span>{" "}
                <span>
                  <a href="mail:info@stmarouns.nsw.edu.au">
                    info@stmarouns.nsw.edu.au
                  </a>
                </span>
              </li>
              <li>
                <span>
                  <i className="fas fa-map-marker-alt"></i>
                </span>{" "}
                <span>194 Wardell Road, Marrickville 2204</span>
              </li>
            </ul>
          </div>
        </div>
        <div className="col-md-5">
          <div className="footer-block">
            <h4>Information</h4>
            <div className="footer-block-column-wrapper">
              <ul>
                <li>
                  <Link to="/about/uniformshop">Uniform Shop</Link>

                </li>

                <li>
                  <Link to="/contact">Location & Transport</Link>
                </li>
                <li>
                  <Link to="/contact">Map</Link>
                </li>
                <li>
                  <Link to="/about/byod">BYOD</Link>
                </li>
              </ul>
              <ul>

                <li>
                  <Link to="/ourcommunity">Parent Association</Link>
                </li>
                <li>
                  <Link to="/about/policies">Policies</Link>
                </li>
                <li>
                  <a href={termDates}>Term Dates 2024</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="col-md-3">
          <div className="footer-block">
            <h4>Social Media</h4>
            <ul className="d-flex flex-row social-media">
              <li>
                <Link to="https://www.facebook.com/stmarounscollege">
                  <i className="fab fa-facebook-square"></i>
                </Link>
              </li>
              <li>
                <Link to="https://www.youtube.com/channel/UCGjbgeaFx_UN9oOmPCs5hOw">
                  <i className="fab fa-youtube-square"></i>
                </Link>
              </li>

              <li>
                <Link to="https://www.instagram.com/stmarounscollege/">
                  <i className="fab fa-instagram-square"></i>
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default Footer;
